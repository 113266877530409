// check for external link on click, show cinfirmation modal if yes

import { Modal } from 'bootstrap/dist/js/bootstrap.esm.js';

let newSiteModal = null,
  modalAnchor = null,
  spanSiteName = null;

const modalHTML = `
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          You're about to open the website
          '<span
            id="newSiteModalName"
            class="fw-bold"
          ></span>' in a new window.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >Cancel</button>
          <a
            id="newSiteModalLink"
            href="#"
            type="button"
            class="btn btn-outline-dark"
            target="_blank"
          > Open </a>
        </div>
      </div>
    </div>
`;

// handle click on modal's "visit" link
function handleModalVisitClick (ev) {
  ev.preventDefault();
  newSiteModal.hide();
  window.open(modalAnchor.href, modalAnchor.host);
}

// add modal to DOM
function addModalToDOM () {
  let modal = document.querySelector('#newSiteModal');
  if (modal) { return; }

  const modalDiv = document.createElement('div');
  modalDiv.id = 'newSiteModal';
  modalDiv.className = 'modal fade';
  modalDiv.tabIndex = -1;
  modalDiv.innerHTML = modalHTML;
  document.body.appendChild(modalDiv);
  modalAnchor = document.querySelector('#newSiteModalLink');
  spanSiteName = document.querySelector('#newSiteModalName');

  return modalDiv;
}

// handle all anchor clicks
function handleAnchorClick (ev) {
  if (ev.target.tagName !== 'A') { return; }

  const a = ev.target;
  if (a.host !== window.location.host) {
    // an external link: show confirm/cancel modal
    ev.preventDefault();
    modalAnchor.href = a.href;
    spanSiteName.innerHTML = a.host;
    newSiteModal.show();
  }
}

window.addEventListener('load', function () {

  // add modal to DOM if necessary
  addModalToDOM();

  // listen for clicks on modal's "visit" link
  modalAnchor.addEventListener('click', handleModalVisitClick);

  // create bootstrap modal object
  newSiteModal = new Modal(document.querySelector('#newSiteModal'));

  // listen for 'click' events in <main>
  document.querySelector('main')
    .addEventListener('click', handleAnchorClick);
});
